const czDates = {
    january: "Leden",
    february: "Únor",
    march: "Březen",
    april: "Duben",
    may: "Květen",
    june: "Červen",
    july: "Červenec",
    august: "Srpen",
    september: "Září",
    october: "Říjen",
    november: "Listopad",
    december: "Prosinec",
}

const convertToCzDate = (dateString) => {
    const month = dateString.split(" ")[0];
    return dateString.replace(month, czDates[month.toLowerCase()]);
}

module.exports = {
    convertToCzDate
}